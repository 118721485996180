import React from "react"
import SimpleReactValidator from "simple-react-validator"
import { navigateTo } from "gatsby"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class clientReferralForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      business: "",
      mobile: "",
      email: "",
      url: "",
      why: "",
      referrername: "",
      referreremail: "",
      submitting: false,
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = e => {
    if (this.validator.allValid()) {
      const form = e.target;
      this.setState({
        submitting: true
      })

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, business, mobile, email, url, why, referrername, referreremail } = this.state;
    return (
      <form
        name="Client Referral Form"
        onSubmit={this.handleSubmit}
        className="w-full max-w-md mx-auto overflow-hidden top-o contactform"
        method="post"
        action="/thank-you-client-referral"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" className="hidden" name="form-name" value="client-referral-form" />
        <p hidden className="hidden"><label>Don’t fill this out:{" "}<input name="bot-field" /></label></p>
          
        <label htmlFor="name" className="block mb-2">
            <span className="block pt-2 pb-1 text-white font-display">Name: *</span>
            <input type="text" name="name" id="name" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Name" value={name} onChange={this.handleChange} />

            <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('name', name, 'required')}
            </span>
        </label>

        <label htmlFor="business" className="block mb-2">
          <span className="block pt-2 pb-1 text-white font-display">Business Name: *</span>
          <input type="business" name="business" id="business" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Business Name" value={business} onChange={this.handleChange} />
            
          <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('business', business, 'required')}
          </span>
        </label>

        <label htmlFor="mobile" className="block mb-2">
          <span className="block pt-2 pb-1 text-white font-display">Mobile Number: *</span>
          <input type="text" name="mobile" id="mobile" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Mobile Number" value={mobile} onChange={this.handleChange} />

          <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('mobile', mobile, 'required|phone')}
          </span>
        </label>

        <label htmlFor="email" className="block mb-2">
          <span className="block pt-2 pb-1 text-white font-display">Email: *</span>
          <input type="email" name="email" id="email" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Email" value={email} onChange={this.handleChange} />
            
          <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('email', email, 'required|email')}
          </span>
        </label>

        <label htmlFor="website" className="block mb-2">
          <span className="block pt-2 pb-1 text-white font-display">Website URL: *</span>

          <input type="text" name="url" id="url" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="https://example.co.uk" value={url} onChange={this.handleChange} />
            
          <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('url', url, 'required')}
          </span>
        </label>

        <label htmlFor="why" className="block mb-6">
          <span className="block pt-2 pb-1 text-white font-display">Why do they need help from us?: *</span>
          <input type="why" name="why" id="why" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Answer" value={why} onChange={this.handleChange} />
            
          <span className="block mt-2 text-sm text-secondary-light">
            {this.validator.message('why', why, 'required')}
          </span>
        </label>

        <div className="flex flex-wrap pt-2 my-2 border-t-2 border-white md:-mx-3">
          <div className="w-full md:w-1/2 md:px-3">
            <label htmlFor="referrername" className="block mb-2">
              <span className="block pt-2 pb-1 text-white font-display">Referrer Name: *</span>
              <input type="text" name="referrername" id="referrername" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Referrer Name" value={referrername} onChange={this.handleChange} />

              <span className="block mt-2 text-sm text-secondary-light">
                {this.validator.message('referrername', referrername, 'required')}
              </span>
            </label>
          </div>
          <div className="w-full md:w-1/2 md:px-3">
            <label htmlFor="referreremail" className="block mb-2">
            <span className="block pt-2 pb-1 text-white font-display">Referrer Email: *</span>
            <input type="referreremail" name="referreremail" id="referreremail" className="block w-full p-3 border-4 border-transparent outline-none appearance-none focus:border-secondary-light" placeholder="Email" value={referreremail} onChange={this.handleChange} />
                
            <span className="block mt-2 text-sm text-secondary-light">
                {this.validator.message('referreremail', referreremail, 'required|email')}
            </span>
            </label>
          </div>
        </div>

        <div className="flex justify-center">
          <button type="submit" disabled={this.state.submitting} className="btn btn--primary">{ this.state.submitting ? 'Sending...' : 'Send Enquiry' }</button>
        </div>
      </form>
    )
  }
}

export default clientReferralForm
